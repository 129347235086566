import React, { useEffect, useState, useCallback, useRef } from 'react';
import { dayselector, daystring, datestring } from './functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TbBellOff, TbBellFilled, TbX, TbCheckbox, TbSquare } from "react-icons/tb"; 

const hoursOfDay = Array.from({ length: 24 }, (_, i) => i);


function MonthTable({ tasks, onDragEnd, deleteTask, manipulateDate, handleWeekChange, weektableday, alarmToggle, handleTaskSetting, changetask }) {
    const divRef = useRef(null);
  // 태스크를 요일과 시간에 따라 그룹화
  const groupedTasks = tasks.reduce((acc, task) => {
    const key = `${task.day}-${task.hour}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(task);
    return acc;
  }, {});

  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const [scroll, setScroll] = useState(0);

  const [daymax, setDaymax] = useState(50);
  const [daymin, setDaymin] = useState(0);


    useEffect(() => {
        setDaysOfWeek([]);
        let days = [];
        console.log(new Date(new Date().getFullYear(), 0, 1));
        for(let i = 0; i < 50; i++) {
            days.push(manipulateDate(new Date(weektableday), i));
        }
        setDaysOfWeek(days);
    }, []);
    
  const handleScroll = useCallback(() => {
    const div = divRef.current;
    
    if (div) {
      // 스크롤 맨 위에 도달했는지 확인
      if (div.scrollTop === 0) {
        let days = [];
        for(let i = daymin - 5; i < daymin; i++) {
            days.push(manipulateDate(new Date(weektableday), i));
        }
        setDaymin(daymin => daymin - 5);
        setDaysOfWeek(daysOfWeek => days.concat(daysOfWeek));
        setScroll(divRef.current.scrollHeight);
      }

      // 스크롤 맨 아래에 도달했는지 확인
      if (div.scrollHeight - div.scrollTop === div.clientHeight) {
        let days = [];
        for(let i = daymax; i < daymax + 15; i++) {
            days.push(manipulateDate(new Date(weektableday), i));
        }
        setDaymax(daymax => daymax + 15);
        setDaysOfWeek(daysOfWeek => daysOfWeek.concat(days));
      }
    }
  }, [daymin, daymax]);

    useEffect(() => {
        divRef.current.scrollTop = divRef.current.scrollHeight - scroll;
    }, [scroll]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='day-table'
      ref={divRef} 
      onScroll={handleScroll} >
            {daysOfWeek.map(day => (
                <div>
                    {
                        new Date(day).getDate() == 1 ?
                            <div className='day-th'>{String(new Date(day).getFullYear())}년 {String(new Date(day).getMonth() + 1)}월</div>
                        : null
                    }
                    <Droppable key={daystring(day)} droppableId={daystring(day)}>
                        {(provided) => (
                        <div className='month-div' ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            new Date(day).getDate == new Date().getDate() ?
                            <div className='day-th-underline'>{datestring(day)} {dayselector(day)}</div> :
                            <div className='day-th' onClick={() => handleWeekChange(day)}>{datestring(day)} {dayselector(day)}</div>
                        }
                            {tasks
                            .filter(task => task.day === daystring(day)).sort((a, b) => ((a.hour.split(':')[0] * 60) + (a.hour.split(':')[1])) - ((b.hour.split(':')[0] * 60) + (b.hour.split(':')[1])))
                            .sort((a, b) => {return (a.allday === b.allday)? 0 : a.allday? -1 : 1;})
                            .map((task, index) => (
                                <Draggable key={task.id} draggableId={String(task.id)} index={index} >
                                {(provided) => (
                                    <div
                                        className='month-td'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    >
                                    <span className='month-p' onClick={() => handleTaskSetting(task)}>
                                      {
                                        task.allday ?
                                        null :
                                        task.hour } {task.title === '' ? 'Todo' : task.title}
                                    </span>
                                    {
                                        task.alarm ? 
                                        <TbBellFilled className='day-bell' 
                                        onClick={() => changetask(task.id, '', 'alarm')}/> : <TbBellOff className='day-bell' 
                                        onClick={() => changetask(task.id, '', 'alarm')}/>
                                    }
                                    {
                                        task.check ? 
                                        <TbCheckbox className='day-bell' onClick={() => changetask(task.id, false, 'check')}/> : 
                                        <TbSquare className='day-bell' onClick={() => changetask(task.id, true, 'check')}/>
                                    }
                                    </div>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                </div>
            ))}
      </div>
    </DragDropContext>
  );
}

export default MonthTable;
