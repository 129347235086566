import React, {useEffect, useRef} from 'react';
import { dayselector, daystring, datestring } from './functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TbBellOff, TbBellFilled, TbX, TbCircleChevronLeft, TbCircleChevronRight, TbCheckbox, TbSquare } from "react-icons/tb"; 

const hoursOfDay = Array.from({ length: 24 }, (_, i) => i);


function Weektable({tasks, onDragEnd, deleteTask, manipulateDate, handleWeekdayChange, handleWeekChange, weektableday, handleTaskSetting, changetask}) {
  // 태스크를 요일과 시간에 따라 그룹화
  const groupedTasks = tasks.reduce((acc, task) => {
    const key = `${task.day}-${task.hour}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(task);
    return acc;
  }, {});
  
  const weekref = useRef(null);

  var weekstring = datestring(manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1) +1)) + ' ~ ' + datestring(manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1)+7));

  const daysOfWeek = [manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1) +1), manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1)+2),
    manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1)+3), manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1)+4),
    manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1)+5), manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1)+6),
    manipulateDate(new Date(weektableday),  (new Date(weektableday).getDay()*-1)+7)];

    if(new Date(weektableday).getDay() == 0) {
        daysOfWeek[0] = manipulateDate(new Date(weektableday),  -6);
        daysOfWeek[1] = manipulateDate(new Date(weektableday),  -5);
        daysOfWeek[2] = manipulateDate(new Date(weektableday),  -4);
        daysOfWeek[3] = manipulateDate(new Date(weektableday),  -3);
        daysOfWeek[4] = manipulateDate(new Date(weektableday),  -2);
        daysOfWeek[5] = manipulateDate(new Date(weektableday),  -1);
        daysOfWeek[6] = manipulateDate(new Date(weektableday),  0);
        weekstring = datestring(manipulateDate(new Date(weektableday),  -6)) + ' ~ ' + datestring(manipulateDate(new Date(weektableday),  0));
    }

    useEffect(() => {
      console.log(new Date(weektableday).getDay())

    }, [])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
    <div className='week-header'> 
        <TbCircleChevronLeft className='week-left' onClick={() => handleWeekdayChange(manipulateDate(new Date(weektableday), -7))}/>
        <div className='week-title'>
        {weekstring}
        </div>
        <TbCircleChevronRight className='week-right' onClick={() => handleWeekdayChange(manipulateDate(new Date(weektableday), 7))}/>
    </div>
      <div className='week-table'>
        <div className='week-tbody'>
          <div className='week-scroll' ref={weekref}>
            {daysOfWeek.map(day => (
              <Droppable key={daystring(day)} droppableId={daystring(day)}>
                {(provided) => (
                <div className='week-td'>
                    <div className='week-th' key={day} onClick={()=> handleWeekChange(day)}>{dayselector(day)}</div>
                        <div ref={provided.innerRef} {...provided.droppableProps} 
                        className={daystring(day) === daystring(new Date()) ? 'week-drop-background' : 'week-drop'}>
                            {tasks
                            .filter(task => task.day === daystring(day)).sort((a, b) => ((a.hour.split(':')[0] * 60) + (a.hour.split(':')[1])) - ((b.hour.split(':')[0] * 60) + (b.hour.split(':')[1])))
                            .sort((a, b) => {return (a.allday === b.allday)? 0 : a.allday? -1 : 1;})
                            .map((task, index) => (
                                <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                                {(provided) => (
                                    <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className='week-drag'
                                    >
                                    <div className='week-p' onClick={() => handleTaskSetting(task)}>
                                      {
                                        task.allday ?
                                        null :
                                        <span>
                                          {
                                        task.hour} <br/>
                                        </span> }{task.title === '' ? 'Todo' : task.title}</div>
    
                                            {
                                                task.alarm ? 
                                                <TbBellFilled className='day-bell' 
                                                onClick={() => changetask(task.id, '', 'alarm')}/> : <TbBellOff className='day-bell' 
                                                onClick={() => changetask(task.id, '', 'alarm')}/>
                                            }
                                            {
                                                task.check ? 
                                                <TbCheckbox className='day-bell' onClick={() => changetask(task.id, false, 'check')}/> : 
                                                <TbSquare className='day-bell' onClick={() => changetask(task.id, true, 'check')}/>
                                            }
                                    </div>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                </div>
                )}
              </Droppable>
            ))}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}

export default Weektable;
