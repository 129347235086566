import React, { useEffect, useState, useCallback, useRef } from 'react';
import { dayselector, daystring, datestring } from './functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TbCardsFilled, TbCalendar, TbPlus, TbMinus, TbX, TbUser, TbHeart } from "react-icons/tb";
import { FaRegFaceGrin, FaImage, FaBolt, FaGlassWater, FaUtensils, FaClockRotateLeft, FaTableTennisPaddleBall,
    FaHatWizard, FaCompass } from "react-icons/fa6";
import { auth, db } from "../firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { setDoc } from 'firebase/firestore';
import { set } from 'firebase/database';


function ProfilePage({ }) {

    const [stickers, setStickers] = useState([]); 
    const divRef = useRef(null);

    const [starttime, setStarttime] = useState(new Date());
    const [chunkmax, setChunkmax] = useState(15);
    const [chunkmin, setChunkmin] = useState(0);

    const [stickertime, setStickertime] = useState([]);
    const [ShowStickerDiv, setShowStickerDiv] = useState(false);

    const [IconCategory, setIconCategory] = useState('mood');

    const [scrollcontrol, setScrollcontrol] = useState(false);
    const [range, setRange] = useState(1);

const rangenumbers = [1, 5, 10, 60, 180, 360, 1440, 4320, 10080]

    function handleGoogleLogin() {
        const provider = new GoogleAuthProvider(); // provider를 구글로 설정

        signInWithRedirect(auth, provider);
    }
      
    useEffect(() => {
        let stickers = [];
        for(let i = 0; i < 15; i++) {
            stickers.push({time: calctime(i), index: i});
        }
        setChunkmax(15);
        setChunkmin(0);
        setStickers(stickers);
    }, []);

    useEffect(() => {
        let stickers = [];
        for(let i = chunkmin; i < chunkmax; i++) {
            stickers.push({time: calctime(i), index: i});
        }
        setStickers(stickers);
    }, [range]);

    useEffect(() => {
        if(scrollcontrol){
            divRef.current.scrollLeft = 1400;
            setScrollcontrol(false);
        }
    }, [stickers]);

    useEffect(() => {
        if(ShowStickerDiv){
            setIconCategory('mood');
        }
    }, [ShowStickerDiv]);

    const handleScroll = useCallback(() => {
        const div = divRef.current;
        
        if (div) {
            // 스크롤 맨 왼쪽에 도달했는지 확인
            if (div.scrollLeft === 0) {
                let chunks = [];
                for(let i = chunkmin - 10; i < chunkmin; i++) {
                    chunks.push({time: calctime(i), index: i});
                }
                setChunkmin(chunkmin => chunkmin - 10);
                setScrollcontrol(true);
                setStickers(chunks.concat(stickers));
            }
            // 스크롤 맨 오른쪽에 도달했는지 확인
            if (div.scrollWidth - Math.ceil(div.scrollLeft) == div.clientWidth || div.scrollWidth - Math.floor(div.scrollLeft) == div.clientWidth) {
                let chunks = [];
                for(let i = chunkmax; i < chunkmax + 10; i++) {
                    chunks.push({time: calctime(i), index: i});
                }
                setChunkmax(chunkmax => chunkmax + 10);
                setStickers(stickers.concat(chunks));
            }
        }
      }, [chunkmax, chunkmin, stickers, range]);

    function calctime(value){
        let time = new Date(starttime);
        time.setMinutes(time.getMinutes() + (value * rangenumbers[range]));

        return time.getFullYear() + '-' + (time.getMonth()+1) + '-' + time.getDate() + '/' +  time.getHours() + ':' + time.getMinutes();
    }

    function calcdate(value){
        let date = new Date(starttime);
        date.setMinutes(starttime.getMinutes() + (value * rangenumbers[range]));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    function changerange(value){
        if(range + value < 0 || range + value > (rangenumbers.length-1)) return;
        else{
            setRange(range => range + value);
        }
    }
    
  return (
    <div className='profile-div'>
        {
            auth.currentUser ? 
            <div className='profile-header'>
                <img className='profile-user-icon' src={auth.currentUser.photoURL}/>
                <div className='profile-user-text'>{auth.currentUser.displayName}</div>
            </div> :
            <div className='profile-header' onClick={() => handleGoogleLogin()}>
                <TbUser className='profile-user-icon'/>
                <span className='profile-user-text'>로그인이 필요합니다</span>
            </div>
        }
        <div className='profile-calender' ref={divRef} onScroll={handleScroll}>
            <div className='profile-time-button-div'>
                <TbPlus className='profile-time-button' onClick={() => changerange(1)}/>
                <TbMinus className='profile-time-button' onClick={() => changerange(-1)}/>
            </div>
            {
                stickers.map((sticker, index) => (
                    <div className='profile-chunk' key={index}>
                        <div className='profile-sticker-div' onClick={() => {
                            setShowStickerDiv(true);
                        }}>
                            <TbCardsFilled className='profile-sticker' />
                            {sticker.index}
                        </div>
                        <div className='profile-time'>
                            {sticker.time.split('/')[0]}<br/>
                            {sticker.time.split('/')[1]}
                        </div>
                    </div>
                ))
            }
            {
                ShowStickerDiv ? 
                <div className='profile-sticker-list-div'>
                    <div className='profile-category-div'>
                        <FaImage className='profile-category-button'/>
                        <FaRegFaceGrin className={IconCategory == 'mood' ? 'profile-category-button-selected' : 'profile-category-button' } onClick={() => setIconCategory('mood')}/>
                        <FaUtensils className={IconCategory == 'food' ? 'profile-category-button-selected' : 'profile-category-button' } onClick={() => setIconCategory('food')}/>
                        <FaGlassWater className={IconCategory == 'water' ? 'profile-category-button-selected' : 'profile-category-button' } onClick={() => setIconCategory('water')}/>
                        <FaBolt className={IconCategory == 'energy' ? 'profile-category-button-selected' : 'profile-category-button' } onClick={() => setIconCategory('energy')}/>
                        <FaTableTennisPaddleBall className={IconCategory == 'sports' ? 'profile-category-button-selected' : 'profile-category-button' } onClick={() => setIconCategory('sports')}/>
                        <FaCompass className={IconCategory == 'compass' ? 'profile-category-button-selected' : 'profile-category-button' } onClick={() => setIconCategory('compass')}/>
                        <FaHatWizard className={IconCategory == 'wiz' ? 'profile-category-button-selected' : 'profile-category-button' } onClick={() => setIconCategory('wiz')}/>
                    </div>
                    <TbX className='profile-sticker-div-button' onClick={() => setShowStickerDiv(false)}/>
                    <div className='profile-sticker-list'>
                        <div className='profile-select-button'>사진</div>
                        <div className='profile-select-button'>기분</div>
                        <div className='profile-select-button'>배고픔</div>
                        <div className='profile-select-button'>목마름</div>
                        <div className='profile-select-button'>에너지</div>
                        <div className='profile-select-button'>목표</div>
                        <div className='profile-select-button'>이모지</div>
                    </div>
                </div> : null
            }
        </div>
    </div>
  );
}

export default ProfilePage;
