import React, { useState, useRef, useCallback, useEffect } from 'react';
import { TbBellOff, TbBellFilled, TbX, TbAlertSmall, TbAlertSmallOff, TbTrash, TbTrashX, TbCheck, TbCircleChevronLeft, TbCircleChevronRight,
TbSquare, TbCheckbox, TbCircleNumber0, TbCircleNumber1, TbCircleNumber2, TbCircleNumber3, TbCircleNumber4, Tb24Hours, TbPlus } from "react-icons/tb"; 

function TaskComponent({ tasks, setShowtasksetting, addTask, taskId, changetask }) {



  const [trashcan, setTrashcan] = useState(true);

  const [showtasksetting1, setShowtasksetting1] = useState(0);
  const [showtasksettingtype, setShowtasksettingtype] = useState(false);
  const [showtasksetting2, setShowtasksetting2] = useState(false);
  const [showtasksetting3, setShowtasksetting3] = useState(false);
  const [showtasksetting4, setShowtasksetting4] = useState(false);

  const task = tasks.find(task => task.id === taskId);

  var hour = task.hour;
  if(hour.split(':')[0].length === 1) {
    hour = '0' + hour;
  }
  if(hour.split(':')[1].length === 1) {
    hour = hour.split(':')[0] + ':0' + hour.split(':')[1];
  }

  const [taskyear, setTaskyear] = useState(parseInt(task.day.slice(0, 4)));
  const [taskmonth, setTaskmonth] = useState(parseInt(task.day.slice(4, 6)));
  const [taskday, setTaskday] = useState(parseInt(task.day.slice(6, 8)));
  const [taskhour, setTaskhour] = useState(hour);
  const [taskminute, setTaskminute] = useState(parseInt(task.hour.split(':')[1]));

  const titleRef = useRef(null);
  const scrollContainerRef1 = useRef(null);
  const scrollContainerRef2 = useRef(null);
  const scrollContainerRef3 = useRef(null);
  const scrollContainerRef4 = useRef(null);
  const scrollContainerRef5 = useRef(null);

  const [number, setNumber] = useState(0);
  const scrollContainerRef = useRef(null);

  const [routineweek, setRoutineweek] = useState([false, false, false, false, false, false, false]);

  const tags = ['태그1', '태그2', '태그3', '태그4', '태그5', '태그6', '태그7', '태그8', '태그9', '태그10'];
  const dayalarms = ['0시','1시','2시', '3시', '4시', '5시', '6시', '7시', '8시', '9시', '10시', '11시', '12시', '13시', '14시', '15시', '16시', '17시', '18시', '19시', '20시', '21시', '22시', '23시'];
  const timealarms = ['0m','1m', '5m', '10m', '30m', '1h', '2h', '3h', '6h', '12h', '1d', '2d', '3d', '1w', '2w'];
  const colors = ['#ff8888', '#fecccc', '#ffddbb', '#ffeecc', '#ffffcc', '#fefeee', '#cceedd', '#ccffee', '#77ddff', '#bbeeff', '#aabbff', '#ccccff', '#ddbbff', '#eeddff', '#eeeeee'];

  // 스크롤 이벤트 핸들러

  useEffect(() => {
    titleRef.current.focus();
  }, []);


  useEffect(() => {
    if(taskyear < 1) {
      setTaskyear(2000);
    } else if(taskyear > 2100) {
      setTaskyear(2100);
    }
    setTaskyear((prevnumber) => parseInt(prevnumber))
    changetask(taskId, taskyear + task.day.slice(4,8), 'day');
  }, [taskyear]);

  useEffect(() => {
    if(taskmonth > 12) {
      setTaskmonth(1);
    } else if(taskmonth < 1) {
      setTaskmonth(12);
    }
    setTaskmonth((prevnumber) => parseInt(prevnumber))
    if(taskmonth < 10) {
      changetask(taskId, task.day.slice(0,4) + '0' + taskmonth +  task.day.slice(6,8), 'day');
    } else {
      changetask(taskId, task.day.slice(2,4) + taskmonth +  task.day.slice(6,8), 'day');
    }
  }, [taskmonth]);

  useEffect(() => {
    const maxdate = new Date(taskyear, taskmonth, 0).getDate();
    if(taskday > maxdate) {
      setTaskday(1);
    } else if(taskday < 1) {
      setTaskday(maxdate);
    }
    setTaskday((prevnumber) => parseInt(prevnumber))
    if(taskday < 10) {
      changetask(taskId, task.day.slice(0,6) + '0' + taskday, 'day');
    } else {
      changetask(taskId, task.day.slice(0,6) + taskday, 'day');
    }
  }, [taskday]);

  useEffect(() => {
    console.log(taskhour);
    changetask(taskId, taskhour, 'hour');
  }, [taskhour]);

  useEffect(() => {
    if(taskminute > 59) {
      setTaskminute(0);
    } else if(taskminute < 0) {
      setTaskminute(59);
    }
    setTaskminute((prevnumber) => {
      return Number(prevnumber)
    })
  }, [taskminute]);

  function handleshowtasksetting(index) {
    if(index === 0) {
      setShowtasksettingtype(false);
      setShowtasksetting2(false);
      setShowtasksetting3(false);
      setShowtasksetting4(false);
    } else if(index === 1) {
      setShowtasksettingtype(true);
      setShowtasksetting2(false);
      setShowtasksetting3(false);
      setShowtasksetting4(false);
    } else if(index === 2) {
      setShowtasksettingtype(false);
      setShowtasksetting2(!showtasksetting2);
      setShowtasksetting3(false);
      setShowtasksetting4(false);
    } else if(index === 3) {
      setShowtasksettingtype(false);
      setShowtasksetting2(false);
      setShowtasksetting3(!showtasksetting3);
      setShowtasksetting4(false);
    } else if(index === 4) {
      setShowtasksettingtype(false);
      setShowtasksetting2(false);
      setShowtasksetting3(false);
      setShowtasksetting4(!showtasksetting4);
    }
  }

  return (
    <div className='task-div'>
      {
        taskId ?
        <div className='task-component'>
          <input 
          ref={titleRef}
          value={task.title}
          onChange={(e) => changetask(taskId, e.target.value, 'title')}
          placeholder='Todo'
          className='task-title'/>
          <TbX className='task-close' onClick={() => setShowtasksetting(false)}/>
          {
              task.alarm ? 
              <TbBellFilled className='task-bell'
              onClick={() => changetask(task.id, '', 'alarm')}/> : <TbBellOff className='task-bell'
              onClick={() => changetask(task.id, '', 'alarm')}/>
          }
          <textarea
            className='task-input'
            type="text"
            value={task.memo}
            onChange={(e) => changetask(taskId, e.target.value, 'memo')}
            placeholder="memo"
          />
          <div className='task-list-div'>
              <div className='task-time' onClick={()=> {}}>
                <div className='task-time-div'>
                  <div className='task-number-scroll' style={{width : "40px"}}>
                    <input className='task-number-scroll-element' type='number' value={taskyear} onChange={(e) => setTaskyear(e.target.value)} />
                  </div>
                  <div className='task-number-scroll' style={{width : "20px"}}>
                    <input className='task-number-scroll-element' type='number' value={taskmonth} onChange={(e) => setTaskmonth(e.target.value)} />
                  </div>
                  <div className='task-number-scroll' style={{width : "20px"}}>
                    <input className='task-number-scroll-element' type='number' value={taskday} onChange={(e) => setTaskday(e.target.value)} />
                  </div>
                  {
                    task.allday ? null :
                    <div className='task-number-scroll' style={{width : "120px"}}>
                      <input className='task-number-scroll-element' type='time' value={taskhour} onChange={(e) => setTaskhour(e.target.value)} />
                    </div>
                  }
                </div>
                <Tb24Hours className='task-icon' onClick={() => changetask(taskId, '', 'allday')}/>
              </div>
              {
                  showtasksetting1 === 0 ?
                  <div className='task-list' onClick={() => {
                    setShowtasksetting1(1);
                    handleshowtasksetting(1);
                  }}>
                    <div className='task-list-title'>
                      반복
                    </div>
                    <div className='task-list-option-div'>
                      없음
                    </div>
                  </div> :
                  showtasksetting1 === 1 ?
                  <div className='task-list' onClick={() => {
                    if(showtasksettingtype) {
                      setShowtasksetting1(2);
                    }
                    handleshowtasksetting(1);
                  }}>
                    <div className='task-list-title'>
                      반복
                    </div>
                    <div className='task-list-option-div'>
                      매일
                    </div>
                  </div> :
                  showtasksetting1 === 2 ?
                  <div className='task-list' onClick={() => {
                    if(showtasksettingtype) {
                      setShowtasksetting1(3);
                    }
                    handleshowtasksetting(1);
                  }}>
                    <div className='task-list-title'>
                      반복
                    </div>
                    <div className='task-list-option-div'>
                      매주
                    </div>
                  </div> :
                  showtasksetting1 === 3 ?
                  <div className='task-list' onClick={() => {
                    if(showtasksettingtype) {
                      setShowtasksetting1(4);
                    }
                    handleshowtasksetting(1);
                  }}>
                    <div className='task-list-title'>
                      반복
                    </div>
                    <div className='task-list-option-div' >
                      매월
                    </div>
                  </div> :
                  showtasksetting1 === 4 ?
                  <div className='task-list' onClick={() => {
                    setShowtasksetting1(0);
                    handleshowtasksetting(0);
                  }}>
                    <div className='task-list-title'>
                      반복
                    </div>
                    <div className='task-list-option-div'>
                      매년
                    </div>
                  </div> : null
              }
              {
                showtasksettingtype ?
                  showtasksetting1 === 0 ? null :
                  showtasksetting1 === 1 ?
                  <div className='task-detail'>
                    <input className='task-detail-input' type='text' placeholder='0' />일마다
                  </div> :
                  showtasksetting1 === 2 ?
                  <div className='task-detail'>
                    {
                      ['월','화','수','목','금','토','일'].map((day, index) => (
                        routineweek[index] ?
                        <div className='task-detail-day task-selected' onClick={() => {
                          let a = routineweek;
                          a[index] = !a[index];
                          setRoutineweek([...a]);
                          changetask(taskId, routineweek, 'routineweek');
                        }}>
                          {day}
                        </div> :
                        <div className='task-detail-day' onClick={() => {
                          let a = routineweek;
                          a[index] = !a[index];
                          setRoutineweek([...a]);
                          changetask(taskId, routineweek, 'routineweek');
                        }}>
                          {day}
                        </div>
                      ))
                    }
                  </div> :
                  showtasksetting1 === 3 ?
                  <div className='task-detail'>
                    <input className='task-detail-input' type='text' placeholder='0' />일
                  </div> : null
                : null
              }
              <div className='task-list' onClick={()=> {
                handleshowtasksetting(3);
              }}>
                알람
              </div>
              {
                showtasksetting3 ?
                <div className='task-detail'>
                  {
                    task.allday ? 
                    dayalarms.map((alarm, index) => (
                      task.alarms[alarm] ?
                      <div className='task-detail-element-color'
                      onClick={() => changetask(taskId, alarm, 'alarms')}
                      >{alarm}</div> :
                      <div className='task-detail-element'
                      onClick={() => changetask(taskId, alarm, 'alarms')}
                      >{alarm}</div>
                    )) :
                    timealarms.map((alarm, index) => (
                      task.alarms[alarm] ?
                      <div className='task-detail-element-color'
                      onClick={() => changetask(taskId, alarm, 'alarms')}
                      >{alarm}</div> :
                      <div className='task-detail-element'
                      onClick={() => changetask(taskId, alarm, 'alarms')}
                      >{alarm}</div>
                    ))
                  }
                </div> : null
              }
            <div className='task-list'>
              <div className='task-list-title' onClick={()=> {
              handleshowtasksetting(2);
            }}>
                반복
              </div>
              <TbPlus className='task-list-button'/>
            </div>
            {
              showtasksetting2 ?
              <div className='task-detail'>
                {
                  tags.map((tag, index) => (
                    task.tag[tag] ?
                    <div className='task-detail-element-color'
                    onClick={() => changetask(taskId, tag, 'tag')}
                    >{tag}</div> :
                    <div className='task-detail-element'
                    onClick={() => changetask(taskId, tag, 'tag')}
                    >{tag}</div>
                  ))
                }
              </div> : null
            }
            <div className='task-list' onClick={()=> {
              handleshowtasksetting(4);
            }}>
              색상
            </div>
            {
              showtasksetting4 ?
              <div className='task-detail'>
                {
                  colors.map((color, index) => (
                    task.color === color ?
                    <div className='task-color-button-selected'
                    onClick={() => changetask(taskId, color, 'color')}
                    style={{backgroundColor : color}}
                    ></div> :
                    <div className='task-color-button' 
                    onClick={() => changetask(taskId, color, 'color')}
                    style={{backgroundColor : color}}
                    ></div>
                  ))
                }
              </div> : null
            }
          </div>
          <div className='task-option-div'>
            {
              trashcan ?
              <div className='task-trash'>
                <div className='task-option' onClick={() => setTrashcan(false)}>
                  <TbTrash/>
                </div> 
              </div>
              :
              <div className='task-trash'>
                <div className='task-option' onClick={() => {
                  changetask(taskId, '', 'delete');
                  setShowtasksetting(false);
                }}>
                  <TbCheck />
                </div>
                <div className='task-option' onClick={() => setTrashcan(true)}>
                  <TbTrashX/>
                </div>
              </div>
            }
            <div className='task-level' onClick={() => changetask(taskId, 1, 'level')}>
              {
                task.level === 0 ? <TbAlertSmallOff/> :
                task.level === 1 ? <TbCircleNumber1/> :
                task.level === 2 ? <TbCircleNumber2/> :
                task.level === 3 ? <TbCircleNumber3/> :
                task.level === 4 ? <TbCircleNumber4/> : null
              }
            </div>
            {
              task.check ?
              <div className='task-checkbox' onClick={() => changetask(taskId, false, 'check')}>
                <TbCheckbox/>
              </div> :
              <div className='task-checkbox' onClick={() => changetask(taskId, true, 'check')}>
                <TbSquare/>
              </div>
            }
          </div>
        </div> : 
        <div className='task-component'>
          <TbX className='task-close' onClick={() => setShowtasksetting(false)}/>
        </div>
      }
    </div>
  );
}

export default TaskComponent;
