import React, { useEffect, useState } from 'react';
import { daystring, datestring, dayselector } from './functions';
import { FaPaperPlane, FaLayerGroup } from "react-icons/fa";
import { TbCards, TbCalendar, TbPlus, TbX, TbUser, TbNotes } from "react-icons/tb";
import { auth } from "../firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { sendMessage } from './Ai';
import TaskComponent from './TaskComponent';
import YearTable from './YearTable';
import MonthTable from './MonthTable';
import Weektable from './WeekTable';
import Daytable from './DayTable';
import PlanPage from './PlanPage';
import ProfilePage from './Profile';
import CardPage from './CardPage';
import { isCompositeComponent } from 'react-dom/test-utils';

function Planner({ tasks, plans, addPlan, likePlan, addTask, updateplan, deleteTask, onDragEnd, onDragEndPlan, manipulateDate, datestring, changetask, resizescreen }) {

  const pattern = /\[.*?\]/g;

  const [title, setTitle] = useState('');
  const [weektableday, setWeektableday] = useState(new Date());
  const [day, setDay] = useState(daystring(manipulateDate(new Date(weektableday), 0)));
  const [hour, setHour] = useState(String(new Date().getHours()) + ':' + String(new Date().getMinutes()));
  const [level, setLevel] = useState(0);
  const [memo, setMemo] = useState('');
  const [color, setColor] = useState('');
  const [tag, setTag] = useState({});
  const [alarm, setAlarm] = useState(false);
  const [alarms, setAlarms] = useState({});
  const [taskId, setTaskId] = useState('');

  const [showbuttons, setShowbuttons] = useState(false);

  const [showsuggest, setShowsuggest] = useState(false);

  const [TableType, setTableType] = useState('day'); // day, week, month, year

  const [aimsg, setAimsg] = useState('');

  const [msg1, setMsg1] = useState('');
  const [msg2, setMsg2] = useState('');
  const [msg3, setMsg3] = useState('');

  const [showtasksetting, setShowtasksetting] = useState(false);

  useEffect(() => {
    // 서비스 워커 등록
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          
        })
        .catch(err => {
          
        });
    }
  }, []);

  useEffect(() => {
    if(!showtasksetting){
      setTaskId('');
    }
    if(taskId === ''){
      setShowtasksetting(false);
    }
  }, [showtasksetting]);

  useEffect(() => {
    resizescreen();
    setShowtasksetting(false);
  }, [TableType]);

  const askForNotificationPermission = () => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // 여기서 푸시 구독을 처리할 수 있습니다.
      } else {
        console.log('Notification permission denied.');
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title) {
      addTask({ id: Date.now(), title, day, hour, level, memo, color, tag, alarm, alarms });
      setTitle('');
      setDay(daystring(manipulateDate(weektableday, 0)));
      setHour(String(new Date().getHours()) + ':' + String(new Date().getMinutes()));
      setLevel(0);
      setMemo('');
      setColor('');
      setTag({});
      setAlarm(false);
      setAlarms({});
    }
  };
  
  function sendMessageToAI(title) {
    sendMessage(title).then((res) => {
      console.log(res)
      console.log(res.choices[0].message.content);
      setAimsg(res.choices[0].message.content);
      const msgs = res.choices[0].message.content.match(pattern);
      if (msgs) {
        const cleanedMatches = msgs.map(match => match.replace(/\[|\]/g, ''));
        setMsg1(cleanedMatches[0]);
        setMsg2(cleanedMatches[1]);
        setMsg3(cleanedMatches[2]);
      }
      setShowsuggest(true);
    })
  }

  const handleDayChange = (e) => {
    if (TableType === 'day') {
      setTableType('week');
    } else if (TableType === 'week') {
      setTableType('month');
    } else if (TableType === 'month') {
      setTableType('year');
    } else if (TableType === 'year') {
      setWeektableday(new Date());
      setTableType('day');
    } else if (TableType === 'plan') {
      setWeektableday(new Date());
      setTableType('day');
    } else {
      setTableType('day');
    }
    console.log(TableType);
  };

  const handleWeekdayChange = (e) => {
    setWeektableday(weektableday => e)
  }

  const handleWeekChange = (e) => {
    setWeektableday(weektableday => e)
    setTableType('day');
  }
  
  const handleMonthChange = (e) => {
    setWeektableday(weektableday => e)
    setTableType('month');
  }
  
  function handleGoogleLogin() {
    setTableType('profile');
  }

  useEffect(() => {
    setDay(daystring(manipulateDate(new Date(weektableday), 0)));
  }, [weektableday]);

  const pushtest = () => {
    const title = '알림 테스트';
    const options = {
      body: '테스트 알림입니다.',
      tag: 'push-test',
      renotify: true,
    };
    console.log('push test');
    
    navigator.serviceWorker.ready.then(registration => {
      registration.showNotification(title, options);
    });
  };

  const selectmsg = (msg) => {
    const title = msg;
    addTask({ id: Date.now(), title, day, hour, level, memo, color, tag, alarm });
    setTitle('');
    setDay(daystring(manipulateDate(weektableday, 0)));
    setHour(String(new Date().getHours()) + ':' + String(new Date().getMinutes()));
    setLevel('');
    setMemo('');
    setColor('');
    setTag('');
    setAlarm(false);
  }

  function handleTaskSetting(task) {
    setTaskId(taskId => task.id)
    setShowtasksetting(true);
  }


  function MakeTask() {
    if(!showtasksetting){
      const taskidnow = Date.now();
      if(!(TableType === 'day' || TableType === 'week' || TableType === 'month')){
        setWeektableday(new Date());
        setTableType('day');
      }
      addTask({ id: taskidnow, title, day: daystring(manipulateDate(weektableday, 0)), hour: String(new Date().getHours()) + ':' + String(new Date().getMinutes()), level, memo, color, tag, alarm, alarms });
      setTitle('');
      setDay(daystring(manipulateDate(weektableday, 0)));
      setHour(String(new Date().getHours()) + ':' + String(new Date().getMinutes()));
      setLevel(0);
      setMemo('');
      setColor('');
      setTag({});
      setAlarm(false);
      setAlarms({});
      setTaskId(taskidnow);
      setShowtasksetting(true);
    }
  }

  return (
    <div className="planner">
      <div className='footer'>
        <div className='footer-div'>
          {/* <button className='footer-day' onClick={() => handleWeekChange(weektableday)}>
          {weektableday.getFullYear()}-{weektableday.getMonth()+1}-{weektableday.getDate()} {dayselector(weektableday)}
          </button> */}
          <button className='table-button' onClick={() => setTableType('cards')}>
            <TbCards />
          </button>
          <button className='table-button' onClick={() => setTableType('plan')}>
            <TbNotes />
          </button>
          <button className='table-button' onClick={() => handleGoogleLogin()}>
            <TbUser />
          </button>
          <button className='table-button' onClick={() => handleDayChange()}>
            <TbCalendar />
          </button>
          <button className='table-button' onClick={() => MakeTask()} >
            <TbPlus />
          </button>
        </div>
        {
          showsuggest ?
          <div className='suggest-div'>
            <div className='suggest-card' onClick={() => selectmsg(msg1)}>
              {msg1}
            </div>
            <div className='suggest-card' onClick={() => selectmsg(msg2)}>
              {msg2}
            </div>
            <div className='suggest-card' onClick={() => selectmsg(msg3)}>
              {msg3}
            </div>
          </div> : null
        }{/*
        <form onSubmit={handleSubmit} className='form-div'>
          <input
            className='footer-input'
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Task"
          />
          <button className='submit-button' type="submit">
            <FaPaperPlane className='button-icon'/>
          </button>
      </form>*/}
      </div>
      {
        showtasksetting ?
        <TaskComponent
          tasks={tasks}
          setShowtasksetting={setShowtasksetting}
          addTask={addTask}
          taskId={taskId}
          changetask={changetask}
        /> : null
      }
      {
        TableType === 'day' ? <Daytable tasks={tasks} deleteTask={deleteTask} onDragEnd={onDragEnd} manipulateDate={manipulateDate}  weektableday={weektableday} handleTaskSetting={handleTaskSetting} changetask={changetask} /> : 
        TableType === 'week' ? <Weektable tasks={tasks} deleteTask={deleteTask} onDragEnd={onDragEnd} manipulateDate={manipulateDate} handleWeekChange={handleWeekChange} handleWeekdayChange={handleWeekdayChange} weektableday={weektableday} handleTaskSetting={handleTaskSetting}  changetask={changetask} /> :
        TableType === 'month' ? <MonthTable tasks={tasks} deleteTask={deleteTask} onDragEnd={onDragEnd} manipulateDate={manipulateDate} handleWeekChange={handleWeekChange} weektableday={weektableday}  handleTaskSetting={handleTaskSetting} changetask={changetask}  /> :
        TableType === 'year' ? <YearTable tasks={tasks} deleteTask={deleteTask} onDragEnd={onDragEnd} manipulateDate={manipulateDate} handleMonthChange={handleMonthChange} weektableday={weektableday}  handleTaskSetting={handleTaskSetting} /> :
        TableType === 'plan' ? <PlanPage tasks={tasks} plans={plans} updateplan={updateplan} deleteTask={deleteTask} onDragEndPlan={onDragEndPlan} manipulateDate={manipulateDate} handleMonthChange={handleMonthChange} weektableday={weektableday}  handleTaskSetting={handleTaskSetting} /> :
        TableType === 'cards' ? <CardPage tasks={tasks} addPlan={addPlan} likePlan={likePlan} deleteTask={deleteTask} onDragEnd={onDragEnd} manipulateDate={manipulateDate} handleMonthChange={handleMonthChange} weektableday={weektableday}  handleTaskSetting={handleTaskSetting} /> :
        TableType === 'profile' ? <ProfilePage /> :
        null
      }
    </div>
  );
}

export default Planner;
