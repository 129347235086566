import React, { useState, useEffect } from 'react';
import { auth, firestore } from './firebase';
import { setDoc, doc , getDoc, getDocs } from 'firebase/firestore';
import './App.css';
import './css/time.css';
import './css/week.css';
import './css/month.css';
import './css/year.css';
import './css/day.css';
import './css/task.css';
import './css/plan.css';
import './css/footer.css';
import './css/profile.css';
import './css/card.css';
import Sidebar from './Component/Sidebar';
import Planner from './Component/Planner';
import { update } from 'firebase/database';

function App() {
  const [showTimetable, setShowTimetable] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [plans, setPlans] = useState([]);

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if(user) {
        getDoc(doc(firestore, "users", user.uid)).then((doc) => {
          if(doc.exists()) {
            setTasks(doc.data().tasks);
            setPlans(doc.data().plans);
          }
        });
      }
    });

    setScreenSize();
  }, []);

  useEffect(() => {
    if(!auth.currentUser) return;
    if (tasks.length === 0) return;
    setDoc(doc(firestore, "users", auth.currentUser.uid), {
      tasks: tasks
    }, { merge: true });
  }, [tasks]);

  useEffect(() => {
    if(!auth.currentUser) return;
    if (plans){
      if (plans.length === 0) return;
      setDoc(doc(firestore, "users", auth.currentUser.uid), {
        plans: plans
      }, { merge: true });
    } else{
      setPlans([]);
    }
  }, [plans]);

  
  const manipulateDate = (currentDate, daysToAddOrSubtract) => {
    // currentDate를 복제하여 새로운 Date 객체 생성
    const newDate = new Date(currentDate);
  
    // daysToAddOrSubtract 변수만큼 날짜를 더하거나 빼기
    newDate.setDate(newDate.getDate() + daysToAddOrSubtract);
  
    return newDate;
  }

  function updateplan() {
    if(auth.currentUser) {
      getDoc(doc(firestore, "users", auth.currentUser.uid)).then((doc) => {
        if(doc.exists()) {
          setPlans(doc.data().plans);
        }
      });
    }
  }

  function changetask(id, value, change) {
    const reorderedTask = tasks.find(task => task.id === id);
    if(change === 'day') {
      reorderedTask.day = value;
      setTasks([...tasks]);
    } else if(change === 'hour') {
      reorderedTask.hour = value;
      setTasks([...tasks]);
    } else if(change === 'level') {
      if(reorderedTask.level === 0) {
        reorderedTask.level = 1;
      } else if(reorderedTask.level === 1) {
        reorderedTask.level = 2;
      } else if(reorderedTask.level === 2) {
        reorderedTask.level = 3;
      } else if(reorderedTask.level === 3) {
        reorderedTask.level = 4;
      } else if(reorderedTask.level === 4) {
        reorderedTask.level = 0;
      } else{
        reorderedTask.level = 0;
      }
      setTasks([...tasks]);
    } else if(change === 'memo') {
      reorderedTask.memo = value;
      setTasks([...tasks]);
    } else if(change === 'color') {
      if(reorderedTask.color === value) {
        reorderedTask.color = '';
      } else{
        reorderedTask.color = value;
      }
      setTasks([...tasks]);
    } else if(change === 'tag') {
      let a = reorderedTask.tag;
      if(a[value] === true) {
        a[value] = false;
      } else{
        a[value] = true;
      }
      setTasks([...tasks]);
    } else if(change === 'alarm') {
      const reorderedTask = tasks.find(task => task.id === id);
      reorderedTask.alarm = !reorderedTask.alarm;
      setTasks([...tasks]);
    } else if(change === 'alarms') {
      let a = reorderedTask.alarms;
      if(a[value] === true) {
        a[value] = false;
      } else{
        a[value] = true;
      }
      setTasks([...tasks]);
    } else if(change === 'title') {
      reorderedTask.title = value;
      setTasks([...tasks]);
    } else if(change === 'check') {
      if(reorderedTask.check === undefined) {
        reorderedTask.check = true;
      } else{
        reorderedTask.check = !reorderedTask.check;
      }
      setTasks([...tasks]);
    } else if(change === 'allday') {
      if(reorderedTask.allday === undefined) {
        reorderedTask.allday = true;
      }
      reorderedTask.allday = !reorderedTask.allday;
      setTasks([...tasks]);
    } else if(change === 'delete') {
      console.log('delete');
      deleteTask(id);
    }
  }

  function addPlan(value) {
    const findid = plans.find(plan => plan.id === value.id);
    if(findid === undefined) {
      setPlans([...plans, value]);
    }
  }

  function likePlan(id) {
    //plans[id].likes[auth.currentUser.uid] = !plans[id].likes[auth.currentUser.uid];
    getDoc(doc(firestore, "plans" , id)).then((document) => {
      if(document.exists()) {
        const plan = document.data();
        if(plan.likes) {
          if(plan.likes[auth.currentUser.uid] === undefined) {
            plan.likes[auth.currentUser.uid] = true;
          } else{
            plan.likes[auth.currentUser.uid] = !plan.likes[auth.currentUser.uid];
          }
        } else{
          plan.likes = {};
          plan.likes[auth.currentUser.uid] = true;
        }
        // count true in likes
        let count = 0;
        for (const [key, value] of Object.entries(plan.likes)) {
          if(value === true) {
            count++;
          }
        }
        plan.like = count;
        setDoc(doc(firestore, "plans", id), plan);
      }
    });
  }

  const addTask = (newTask) => {
    setTasks([...tasks, newTask]);
  };

  const deleteTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
  
    if (!destination) {
      // 드래그가 취소되었을 때 실행할 로직
      return;
    }
  
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      // 같은 위치로 드래그되었을 때 실행할 로직
      return;
    }
    
    console.log(result)
    console.log(destination)
    console.log(source)
    // draggableId를 사용하여 식별
    const draggedTaskId = parseInt(draggableId, 10); // 문자열을 숫자로 변환 (필요에 따라)
    const destinationDroppableId = destination.droppableId; // 이동한 드롭 영역의 droppableId
  
    // 순서를 변경하기 위해 배열에서 드래그된 태스크를 찾음
    var reorderedTask = tasks.find(task => task.id === draggedTaskId);
    var  tasksInSameSlot = tasks.filter(task => task.day === destinationDroppableId.split('-')[0] && task.id !== draggedTaskId);
      // 새로운 배열을 생성하여 순서 변경
    if(!reorderedTask.allday) {
      console.log(tasksInSameSlot[destination.index])
      if(tasksInSameSlot[destination.index] === undefined) {
        if(destination.index === 0) {
          reorderedTask.day = destinationDroppableId.split('-')[0];
          reorderedTask.hour = tasksInSameSlot[destination.index].hour;
          const otherTasks = tasks.filter(task => task.day !== destinationDroppableId.split('-')[0]);
          tasksInSameSlot = tasks.filter(task => task.day === destinationDroppableId.split('-')[0] && task.id !== draggedTaskId);
          tasksInSameSlot.splice(destination.index, 0, reorderedTask);
          setTasks([...otherTasks, ...tasksInSameSlot]);
          return;
        } else {
          reorderedTask.day = destinationDroppableId.split('-')[0];
          reorderedTask.hour = tasksInSameSlot[destination.index - 1].hour;
          const otherTasks = tasks.filter(task => task.day !== destinationDroppableId.split('-')[0]);
          tasksInSameSlot = tasks.filter(task => task.day === destinationDroppableId.split('-')[0] && task.id !== draggedTaskId);
          tasksInSameSlot.splice(destination.index, 0, reorderedTask);
          setTasks([...otherTasks, ...tasksInSameSlot]);
          return;
        }
      } else{
          reorderedTask.day = destinationDroppableId.split('-')[0];
          reorderedTask.hour = tasksInSameSlot[destination.index].hour;
          const otherTasks = tasks.filter(task => task.day !== destinationDroppableId.split('-')[0]);
          tasksInSameSlot = tasks.filter(task => task.day === destinationDroppableId.split('-')[0] && task.id !== draggedTaskId);
          tasksInSameSlot.splice(destination.index, 0, reorderedTask);
          setTasks([...otherTasks, ...tasksInSameSlot]);
          return;
      }
    }
    console.log(reorderedTask)
  };

  const onDragEndPlan = (result) => {
    const { destination, source, draggableId } = result;
  
    if (!destination) {
      // 드래그가 취소되었을 때 실행할 로직
      return;
    }
  
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      // 같은 위치로 드래그되었을 때 실행할 로직
      return;
    }
    
    var reorderedPlan = plans.find(plan => plan.id === draggableId);
    var otherPlans = plans.filter(plan => plan.id !== draggableId);
    otherPlans.splice(destination.index, 0, reorderedPlan);

    setPlans(otherPlans);
  }

  return (
    <div className="App">
      <div className='main'>
        <Planner tasks={tasks} plans={plans} addPlan={addPlan} likePlan={likePlan} addTask={addTask} deleteTask={deleteTask} onDragEnd={onDragEnd} onDragEndPlan={onDragEndPlan} updateplan={updateplan} manipulateDate={manipulateDate} changetask={changetask} resizescreen={setScreenSize} />
      </div>
    </div>
  );
}

export default App;
