import React, { useEffect, useState } from 'react';
import { dayselector, daystring, datestring } from './functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TbBellOff, TbBellFilled, TbX, TbSquare, TbCheckbox } from "react-icons/tb"; 
import { set } from 'firebase/database';

const hoursOfDay = Array.from({ length: 24 }, (_, i) => i);


function Daytable({ tasks, onDragEnd, deleteTask, manipulateDate, weektableday, alarmToggle, handleTaskSetting, changetask }) {

  // 태스크를 요일과 시간에 따라 그룹화
  const groupedTasks = tasks.reduce((acc, task) => {
    const key = `${task.day}-${task.hour}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(task);
    return acc;
  }, {});

  const [daysOfWeek, setDaysOfWeek] = useState([manipulateDate(weektableday, 0)]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='day-table'>
                <div>
                    <Droppable key={daystring(weektableday)} droppableId={daystring(weektableday)}>
                        {(provided) => (
                        <div className='day-div' ref={provided.innerRef} {...provided.droppableProps}>
                            
                            <div className='day-th' >{datestring(weektableday)} {dayselector(weektableday)}</div>
                            {tasks.filter(task => task.day === daystring(weektableday)).sort((a, b) => ((a.hour.split(':')[0] * 60) + (a.hour.split(':')[1])) - ((b.hour.split(':')[0] * 60) + (b.hour.split(':')[1])))
                            .sort((a, b) => {return (a.allday === b.allday)? 0 : a.allday? -1 : 1;})
                            .map((task, index) => (
                                <Draggable key={task.id} draggableId={String(task.id)} index={index} >
                                {(provided) => (
                                    <div
                                        className='day-td'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    >
                                    <span className='day-p' onClick={() => handleTaskSetting(task)}>
                                      {
                                        task.allday ?
                                        null :
                                        task.hour } {task.title === '' ? 'Todo' : task.title}
                                    </span>
                                    {
                                        task.alarm ? 
                                        <TbBellFilled className='day-bell' 
                                        onClick={() => changetask(task.id, '', 'alarm')}/> : <TbBellOff className='day-bell' 
                                        onClick={() => changetask(task.id, '', 'alarm')}/>
                                    }
                                    {
                                        task.check ? 
                                        <TbCheckbox className='day-bell' onClick={() => changetask(task.id, false, 'check')}/> : 
                                        <TbSquare className='day-bell' onClick={() => changetask(task.id, true, 'check')}/>
                                    }
                                    </div>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                </div>
      </div>
    </DragDropContext>
  );
}

export default Daytable;
