
function dayselector(day){
    const today = new Date(day).getDay();
    
    switch(today){
      case 0:
        return '일';
      case 1:
        return '월';
      case 2:
        return '화';
      case 3:
        return '수';
      case 4:
        return '목';
      case 5:
        return '금';
      case 6:
        return '토';
    }
}

function datestring(day){
    const newDate = String(day.getFullYear()) + '-' + String(day.getMonth() + 1) + '-' + String(day.getDate());
    
    return newDate;
}

function daystring(day){
    var newDate = '';
    if(day.getMonth() + 1 < 10){
        newDate = String(day.getFullYear()) + '0' + String(day.getMonth() + 1) + String(day.getDate());
        if(day.getDate() < 10){
            newDate = String(day.getFullYear()) + '0' + String(day.getMonth() + 1) + '0' + String(day.getDate());
        }
    } else {
        newDate = String(day.getFullYear()) + String(day.getMonth() + 1) + String(day.getDate());
        if(day.getDate() < 10){
            newDate = String(day.getFullYear()) + String(day.getMonth() + 1) + '0' + String(day.getDate());
        }
    }

    return newDate;
}

export {dayselector, daystring, datestring};