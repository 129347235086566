import React, { useEffect, useState, useCallback, useRef } from 'react';
import { dayselector, daystring, datestring } from './functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TbCardsFilled, TbCalendar, TbPlus, TbX, TbUser, TbHeart, TbClockHour2, TbCoins, TbCirclePlus, TbDownload } from "react-icons/tb";

import { auth, firestore } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

function PlanPage({ plans, onDragEndPlan, updateplan}) {

    const [timesum, setTimesum] = useState(0);
    const [costsum, setCostsum] = useState(0);
    
    const [showtasks, setShowtasks] = useState(null);

    useEffect(() => {
        updateplan();
    }, []);

    useEffect(() => {
        let time = 0;
        let cost = 0;
        plans.map((plan) => {
            if(plan.time == null) plan.time = 0;
            time += parseInt(plan.time);
            if(plan.cost == null) plan.cost = 0;
            cost += parseInt(plan.cost);
        });
        setTimesum(time);
        setCostsum(cost);
    }, [plans]);

  return (
    <DragDropContext onDragEnd={onDragEndPlan}>
        <div className='plan-header'>
            <TbClockHour2 className='card-text-icon' /> 
            <span className='card-text-span'>
                {timesum} 시간
            </span>
            <TbCoins className='card-text-icon' /> 
            <span className='card-text-span'>
                {costsum} 원
            </span>
        </div>
        <Droppable droppableId='plans'>
            {
                (provided) => (
                    <div className='plan-table' ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            plans != null ? 
                            plans.map((plan, index) => (
                                <Draggable key={plan.id} draggableId={plan.id} index={index}>
                                    {(provided) => (
                                        <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                            <div className={showtasks == index ? 'card-selected' : 'card'} key={index}
                                            onClick={() => {
                                                if(showtasks == index) setShowtasks(null);
                                                else setShowtasks(index);
                                            }}
                                            >
                                                <div className='card-title'>
                                                    <p className='card-text'>
                                                    {plan.title}
                                                    </p>
                                                    <p className='card-text'>
                                                        <TbClockHour2 className='card-text-icon' /> 
                                                        <span className='card-text-span'>
                                                            {plan.time} 시간
                                                        </span>
                                                        <TbCoins className='card-text-icon' /> 
                                                        <span className='card-text-span'>
                                                            {plan.cost} 원
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                showtasks == index ?
                                                <div className='card-option'>
                                                    {
                                                        plan.tasks.length > 0 ?
                                                        plan.tasks.map((task, index) => (
                                                            <div>
                                                                <p className='card-text'>
                                                                    {task.title}
                                                                </p>
                                                                <p className='card-text'>
                                                                    <TbClockHour2 className='card-text-icon' /> 
                                                                    <span className='card-text-span'>
                                                                        {task.time}
                                                                    </span>
                                                                    <TbCoins className='card-text-icon' /> 
                                                                    <span className='card-text-span'>
                                                                        {task.cost}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        )) : null
                                                    }
                                                    <div className='card-button-div'>
                                                        <TbCirclePlus className='card-option-button' />
                                                        <TbCirclePlus className='card-option-button' />
                                                        <TbDownload className='card-option-button' />
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    )}
                                </Draggable>
                            )) : null
                        }
                        {provided.placeholder}
                    </div>
                )
            }
        </Droppable>
    </DragDropContext>
  );
}

export default PlanPage;
