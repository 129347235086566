import React, { useEffect, useState, useCallback, useRef } from 'react';
import { dayselector, daystring, datestring } from './functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TbCardsFilled, TbCalendar, TbPlus, TbX, TbUser, TbHeart, TbHeartFilled, TbClockHour2, TbCoins, TbCirclePlus, TbDownload, TbShare3 } from "react-icons/tb";
import { auth, firestore } from '../firebase';
import { doc, getDoc, query, where, collection, getDocs, limit, setDoc } from 'firebase/firestore';

function CardPage({addPlan, likePlan }) {

    const [search, setSearch] = useState('');
    const [searchresult, setSearchresult] = useState([]);

    const [MakeTask, setMakeTask] = useState(false);

    const [MakeTaskTitle, setMakeTaskTitle] = useState('');
    const [MakeTaskList, setMakeTaskList] = useState([{
        id : makerandom(),
        title : '',
        time : 0,
        cost : 0,
        like : 0,
        likes : {},
    }]);

    const [inputrefs, setInputrefs] = useState([useRef()]);

    const [test, setTest] = useState('');

    const [showtasks, setShowtasks] = useState(null);

    useEffect(() => {
        getPlans();
    }, []);

    useEffect(() => {
        console.log(searchresult);
    }, [searchresult]);

    useEffect(() => {
        
    }, [MakeTaskList]);

    const handleSearch = async(e) => {
        e.preventDefault();
        setShowtasks(null);
        setSearch(search => e.target.value);
        setSearchresult([])

        if(e.target.value.length > 0) {

            const searchref = query(collection(firestore, 'plans'), where('search', '>=', e.target.value.toUpperCase()), where('search', '<=', e.target.value.toUpperCase() + '\uf8ff'), limit(10));
            const searchsnapshot = await getDocs(searchref);

            let a = []
            searchsnapshot.docs.map((doc) => {
                a.push(doc.data());
            });

            setSearchresult(a);
        } else{
            getPlans();
        }
    }

    const getPlans = async() => {
        const plans = [];
        const searchref = query(collection(firestore, 'plans'), limit(10));
        const searchsnapshot = await getDocs(searchref);

        searchsnapshot.docs.map((doc) => {
            plans.push(doc.data());
        });
        
        setSearchresult(plans);
    }

    const handleMakeTask = async() => {
        if(MakeTaskTitle.length > 0 && MakeTaskList.length > 0) {
            let a = MakeTaskList;
            a.map((task) => {
                task.id = makerandom();
            });
            const plan = {
                id : makerandom(),
                title : MakeTaskTitle,
                time : MakeTaskList.reduce((acc, task) => acc + parseInt(task.time), 0),
                cost : MakeTaskList.reduce((acc, task) => acc + parseInt(task.cost), 0),
                like : 0,
                tasks : MakeTaskList,
                search : MakeTaskTitle.toUpperCase(),
            }
            setMakeTask(false);
            setMakeTaskTitle('');
            setMakeTaskList([{
                id : makerandom(),
                title : '',
                time : 0,
                cost : 0,
                like : 0,
                likes : {},
            }]);
            await setDoc(doc(firestore, 'plans', plan.id.toString()), plan);
            getPlans();
        }
    }

    function makerandom() {
        //random 30char string
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 30; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

  return (
    <div>
        <div className='plan-header'>
            <input className='plan-input' type='text' placeholder='일정을 찾아보세요' onChange={(e) => handleSearch(e)} value={search}></input>
            <button className='plan-button' onClick={() => setMakeTask(!MakeTask)}><TbPlus/></button>
        </div>
        <div className='plan-table'>
            {
                MakeTask ? 
                    <div className='plan-make'>
                        <div className='plan-make-header'>
                            <TbX className='plan-make-icon' onClick={() => setMakeTask(false)}/>
                        </div>
                        <div className='plan-make-title'>
                            <input className='plan-make-head-title' type='text' placeholder='title' onChange={(e) => setMakeTaskTitle(e.target.value)} value={MakeTaskTitle}/>
                        </div>
                        <div className='plan-make-list'>
                            {
                                MakeTaskList.length > 0 ?
                                MakeTaskList.map((task, index) => (
                                    <div className='plan-make-task'>
                                        <div className='plan-make-list-header'>
                                            <TbX className='plan-make-icon small' onClick={() => {
                                                let a = [...MakeTaskList];
                                                a.splice(index, 1);
                                                setMakeTaskList(a);
                                            }}/>
                                        </div>
                                        <div className='plan-make-task-info'>
                                            <p className='card-text'>
                                                <input className='plan-make-input-title' type='text' placeholder='task' key={task.id + 'title'} onChange={(e) => {
                                                    let a = [...MakeTaskList];
                                                    a[index].title = e.target.value;
                                                    setMakeTaskList(a);
                                                }} value={task.title}
                                                />
                                            </p>
                                            <p className='card-text'>
                                                
                                            </p>
                                            <p className='card-text'>
                                                <TbClockHour2 className='card-text-icon' /> 
                                                    <input className='plan-make-input-option' type='number' placeholder='title' key={task.id + 'time'} onChange={(e) => {
                                                        let a = [...MakeTaskList];
                                                        a[index].time = e.target.value;
                                                        setMakeTaskList(a);
                                                    }} value={task.time}
                                                    />
                                                <TbCoins className='card-text-icon' /> 
                                                    <input className='plan-make-input-option' type='number' placeholder='title' key={task.id + 'cost'} onChange={(e) => {
                                                        let a = [...MakeTaskList];
                                                        a[index].cost = e.target.value;
                                                        setMakeTaskList(a);
                                                    }} value={task.cost}
                                                    />
                                            </p>
                                        </div>
                                    </div>
                                )) : null
                            }
                            <div className='plan-make-button'>
                                <TbPlus className='plan-make-icon circle' onClick={() => setMakeTaskList([...MakeTaskList, {
                                    id : makerandom(),
                                    title : '',
                                    time : 0,
                                    cost : 0,
                                }])}/>
                            </div>
                            <div className='plan-make-button'>
                                <button className='plan-make-submit' onClick={() => handleMakeTask()}>
                                    등록하기
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    searchresult.length > 0 ?
                    searchresult.map((plan, index) => (
                        <div>
                            <div className={showtasks == index ? 'card-selected' : 'card'}
                            key={index} onClick={() => {
                                if(showtasks == index) setShowtasks(null);
                                else setShowtasks(index);
                            }}>
                                <div className='card-title'>
                                    <p className='card-text'>
                                    {plan.title}
                                    </p>
                                    <p className='card-text'>
                                        <TbClockHour2 className='card-text-icon' /> 
                                        <span className='card-text-span'>
                                            {plan.time}
                                        </span>
                                        <TbCoins className='card-text-icon' /> 
                                        <span className='card-text-span'>
                                            {plan.cost}
                                        </span>
                                            {
                                                Object.keys(plan.likes).length > 0 ?
                                                    auth.currentUser.uid in plan.likes ?
                                                        plan.likes[auth.currentUser.uid]?
                                                        <TbHeartFilled className='card-text-icon' onClick={() => {
                                                            likePlan(plan.id);
                                                            plan.likes[auth.currentUser.uid] = false;
                                                            plan.like -= 1;
                                                        }}/> 
                                                        :
                                                        <TbHeart className='card-text-icon' onClick={() => {
                                                            likePlan(plan.id);
                                                            plan.likes[auth.currentUser.uid] = true;
                                                            plan.like += 1;
                                                        }}/>
                                                    :
                                                    null
                                                    :
                                                null
                                            }
                                        <span className='card-text-span'>
                                            {plan.like}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {
                                showtasks == index ?
                                <div className='card-option'>
                                    {
                                        plan.tasks.length > 0 ?
                                        plan.tasks.map((task, index) => (
                                            <div>
                                                <p className='card-text'>
                                                    {task.title}
                                                </p>
                                                <p className='card-text'>
                                                    <TbClockHour2 className='card-text-icon' /> 
                                                    <span className='card-text-span'>
                                                        {task.time}
                                                    </span>
                                                    <TbCoins className='card-text-icon' /> 
                                                    <span className='card-text-span'>
                                                        {task.cost}
                                                    </span>
                                                </p>
                                            </div>
                                        )) : null
                                    }
                                    <div className='card-button-div'>
                                        <TbShare3 className='card-option-button' />
                                            {
                                                Object.keys(plan.likes).length > 0 ?
                                                    auth.currentUser.uid in plan.likes ?
                                                        plan.likes[auth.currentUser.uid]?
                                                        <TbHeartFilled className='card-option-button' onClick={() => {
                                                            plan.likes[auth.currentUser.uid] = false;
                                                            console.log(plan.likes);
                                                        }}/> 
                                                        :
                                                        <TbHeart className='card-option-button' onClick={() => {
                                                            plan.likes[auth.currentUser.uid] = true;
                                                            console.log(plan.likes);
                                                        }}/>
                                                    :
                                                    null
                                                    :
                                                null
                                            }
                                            {
                                                console.log(plan.likes)
                                            }
                                        <TbDownload className='card-option-button' onClick={() => addPlan(plan)} />
                                    </div>
                                </div> : null
                            }
                        </div>
                    )) : null
            }
        </div>
    </div>
  );
}

export default CardPage;
