import OpenAI from 'openai';
const openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true,
});

export async function sendMessage(message){
    // get response from openai with the message
    const response = openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
            {
                "role": "system", "content": message + "3가지를 추천하는데 추천사항은 [] 안에 넣어줘. 예를 들면, [추천행동] 이런 식으로 말이야."
            }
        ],
    })
    return response;
}