import React, { useEffect, useState, useCallback, useRef } from 'react';
import { dayselector, daystring, datestring } from './functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const hoursOfDay = Array.from({ length: 24 }, (_, i) => i);


function YearTable({ tasks, onDragEnd, deleteTask, manipulateDate, handleMonthChange, weektableday }) {
    const yearRef = useRef(null);
  // 태스크를 요일과 시간에 따라 그룹화
  const groupedTasks = tasks.reduce((acc, task) => {
    const key = `${task.day}-${task.hour}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(task);
    return acc;
  }, {});

      const [yearmin, setYearmin] = useState(-1);
    const [yearmax, setYearmax] = useState(30);
    const [monthsOfYear, setMonthsOfYear] = useState([]);

    useEffect(() => {
        let a = [];
        for(let i = yearmin+1; i < yearmax; i++) {
            a.push(new Date(new Date().getFullYear() + i, 0, 1));
        }
        setMonthsOfYear(monthsOfYear => a);
    }, []);

  const handleScroll = useCallback(() => {
    const div = yearRef.current;
    
    if (div) {
      // 스크롤 맨 위에 도달했는지 확인
      if (div.scrollTop === 0) {
        let a = [];
        console.log(yearmin);
        a.push(new Date(new Date().getFullYear() + yearmin, 0, 1));
        setYearmin(yearmin => yearmin - 1);
        setMonthsOfYear(monthsOfYear => a.concat(monthsOfYear));
        yearRef.current.scrollTop = 10;
      }

      // 스크롤 맨 아래에 도달했는지 확인
      if (div.scrollHeight - div.scrollTop === div.clientHeight) {
        let a = [];
        a.push(new Date(new Date().getFullYear() + yearmax, 0, 1));
        setYearmax(yearmax => yearmax + 1);
        setMonthsOfYear(monthsOfYear => monthsOfYear.concat(a));
      }
    }
  }, [yearmin, yearmax]);


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='day-table'
      ref={yearRef} 
      onScroll={handleScroll} >
        {
            monthsOfYear.map(month => (
                <div>
                    {
                        new Date(month).getMonth() == 0 ?
                            <div className='year-th'>{String(new Date(month).getFullYear())}년</div>
                        : null
                    }
                    <div className='year-div'>
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(day => (
                                <div className='year-td' onClick={()=> handleMonthChange(new Date(new Date(month).getFullYear(), day-1, 1))}>{day}월</div>
                            ))
                        }
                    </div>
                </div>
            ))
        }
        
      </div>
    </DragDropContext>
  );
}

export default YearTable;
