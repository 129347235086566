
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDqTaZROolgUHUZhgiQlrAR0EqOgYR-w2o",
    authDomain: "aiplan-bb815.firebaseapp.com",
    projectId: "aiplan-bb815",
    storageBucket: "aiplan-bb815.appspot.com",
    messagingSenderId: "273075155846",
    appId: "1:273075155846:web:dee4b4c89fab6048b13207"
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);